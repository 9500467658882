@font-face
  font-family: Baloo2
  src: url(/assets/fonts/Baloo_2/Baloo2-VariableFont_wght.ttf) format('opentype')

*
  box-sizing: border-box
  padding: 0
  margin: 0
  font-family: Baloo2

body
  max-width: 100vw
  overflow-x: hidden
  background: black
  min-height: 100vh

  *
      color: white
      line-height: 1

h1, h2, h3, h4, b
  font-weight: 600

li
  list-style-type: none

a
  color: inherit
  text-decoration: none

// p, a
//   font-family: Kim-Light

button
  border: none
  cursor: pointer

input
  &:focus-visible
    outline: none

.page
  @media screen and (max-width: 800px)
    margin-top: 70px

.content
  width: 100%
  max-width: 1500px
  margin: 0 auto
  overflow: hidden

  @media screen and (max-width: 1400px)
    max-width: 1100px

  @media screen and (max-width: 1000px)
    padding-left: 15px !important
    padding-right: 15px !important

.row
  display: flex
  flex-direction: row
  align-items: center

.column
  display: flex
  flex-direction: column
  align-items: center

.noselect
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

.cut-text
  text-overflow: ellipsis
  overflow: hidden 
  width: 160px 
  height: 1.2em
  white-space: nowrap

.ck-content
  *
    // font-family: Kim-Light

    ul
        list-style-type: disc

    ol
        list-style-type: decimal

    ul, ol
        margin: 0
        padding: inherit

.ck-content li
    list-style-type: inherit
    margin: 0
    padding: inherit

.landing
    min-height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    position: relative
    overflow: hidden
    z-index: 1
    background-image: url(../assets/images/particles.png)
    background-position: center
    background-size: cover
    background-repeat: no-repeat

    &.content
        max-width: 1920px
        position: relative

        .left-imgs
            position: fixed
            left: 0
            bottom: 0
            z-index: 10

            .kratos_atreus
                position: absolute
                z-index: 1
                bottom: -185px
                left: -200px
                width: 850px

            .playstation_card
                position: absolute
                z-index: 2
                bottom: 45px
                left: 0
                width: 300px

            .xbox_card
                position: absolute
                z-index: 3
                bottom: 0px
                left: 0
                width: 250px

            .control
                position: absolute
                z-index: 4
                bottom: -120px
                left: 80px
                width: 420px
                transform: scaleX(-1)

        .right-imgs
            position: fixed
            right: 0
            bottom: 0
            z-index: 10

            .tv
                position: absolute
                z-index: 1
                bottom: -320px
                right: -630px
                width: 1000px

            .laptop
                position: absolute
                z-index: 2
                bottom: -50px
                right: 120px
                width: 500px
                transform: scaleX(-1)

            .cellphones
                position: absolute
                z-index: 3
                bottom: -40px
                right: -52px
                width: 350px

    &::after
        content: ''
        width: 100%
        height: 100%
        background-image: url(../assets/images/circle_lines.png)
        background-position: bottom
        background-repeat: no-repeat
        position: absolute
        z-index: -1
        bottom: 0

    &::before
        z-index: -2
        position: absolute
        bottom: -500px
        width: 100%
        height: 1200px
        content: ''
        background-size: contain
        background-repeat: no-repeat
        background-position: center
        background-image: radial-gradient(rgba(0, 72, 255, 0.197) 35%, black 65%)

    .main-logo
        width: 300px
        padding-top: 80px

    .intro-text
        display: flex
        flex-direction: column
        align-items: center
        gap: 20px
        padding-top: 50px

        h2
            font-size: 50px

            span
                color: #0047FF

    .link-blocks-containers
        gap: 50px
        margin: auto

        .link-blocks-container
            width: 445px
            .logo
                display: block
                margin: 0 auto 20px

            .link-blocks
                width: 100%
                display: flex
                flex-direction: row
                flex-wrap: wrap
                justify-content: center
                gap: 10px

            a
                flex: 1
                flex-basis: 40%

            .link-block
                width: 100%
                padding: 10px
                cursor: pointer
                transition: 0.2s
                border-radius: 15px
                position: relative
                overflow: hidden
                z-index: 1

                &::after
                    content: ''
                    width: 120%
                    height: 120%
                    position: absolute
                    background: rgba(255, 255, 255, 0.05)
                    backdrop-filter: blur(1.5px)
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)
                    z-index: -1

                &:hover
                    background: #595959

                .row
                    margin-bottom: 10px
                    justify-content: space-between

                    .icon
                        height: 18px

                &.no-description
                    .row
                        justify-content: flex-start
                        margin-bottom: 0

                        .icon
                            margin-right: 10px

                        .arrow
                            margin-left: auto

    .landing-footer
        display: flex
        flex-direction: column
        align-items: center
        gap: 50px
        position: relative
        overflow: hidden
        z-index: 100
        margin-bottom: 30px

        .group
            display: flex
            flex-direction: column
            align-items: center
            gap: 5px

        .bottom-links
            gap: 20px

    @media screen and (min-width: 2600px)
        .left-imgs
            transform: scale(1.6)
            
        .right-imgs
            transform: scale(1.6)

    @media screen and (min-width: 3000px)
        .left-imgs
            transform: scale(2)
            
        .right-imgs
            transform: scale(2)

    @media screen and (min-width: 3500px)
        .left-imgs
            transform: scale(2.2)
            
        .right-imgs
            transform: scale(2.2)

    @media screen and (max-width: 1400px)
        .main-logo
          padding-top: 35px

        .landing-footer
          gap: 60px

        .intro-text
            padding-top: 30px
            gap: 10px
            h2
                font-size: 32px

        .link-blocks-containers
            gap: 25px

        .landing-footer
            gap: 25px

        .left-imgs
            transform: scale(.6)
            
        .right-imgs
            transform: scale(.6)

    @media screen and (max-width: 1000px)
        .main-logo
            width: 90%
            max-width: 280px
            padding-top: 25px

        .intro-text
            padding-bottom: 50px

            *
              text-align: center

            h2
                font-size: 35px

        .link-blocks-container
            width: 100% !important
    
        .landing-footer
            padding: 25px 15px
            gap: 50px

        .left-imgs, .right-imgs
            display: none

